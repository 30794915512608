<template>
  <div></div>

</template>

<script>
import { environment } from "../../environment/environment";
export default {
  name: "Logout",
  created() {
    let uri = encodeURIComponent(`${environment.path}`)
    let url = `https://keycloak.actiportal.ch/realms/master/protocol/openid-connect/logout?post_logout_redirect_uri=${uri}&id_token_hint=${this.$keycloak.idToken}&clientId=${this.$keycloak.clientId}`
    // let url = `http://localhost:8081/realms/master/protocol/openid-connect/logout`

    console.log(url)
    window.location.href = `${url}`;
  },
}
</script>

<style scoped>

</style>